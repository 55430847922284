// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-branding-js": () => import("/Users/doug/apps/Mainframe2/websites/packages/mainframe.com/src/pages/branding.js" /* webpackChunkName: "component---src-pages-branding-js" */),
  "component---src-pages-brazil-js": () => import("/Users/doug/apps/Mainframe2/websites/packages/mainframe.com/src/pages/brazil.js" /* webpackChunkName: "component---src-pages-brazil-js" */),
  "component---src-pages-community-js": () => import("/Users/doug/apps/Mainframe2/websites/packages/mainframe.com/src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-contact-js": () => import("/Users/doug/apps/Mainframe2/websites/packages/mainframe.com/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/Users/doug/apps/Mainframe2/websites/packages/mainframe.com/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("/Users/doug/apps/Mainframe2/websites/packages/mainframe.com/src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-legal-privacy-js": () => import("/Users/doug/apps/Mainframe2/websites/packages/mainframe.com/src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-legal-terms-js": () => import("/Users/doug/apps/Mainframe2/websites/packages/mainframe.com/src/pages/legal/terms.js" /* webpackChunkName: "component---src-pages-legal-terms-js" */),
  "component---src-pages-roadmap-js": () => import("/Users/doug/apps/Mainframe2/websites/packages/mainframe.com/src/pages/roadmap.js" /* webpackChunkName: "component---src-pages-roadmap-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/doug/apps/Mainframe2/websites/packages/mainframe.com/.cache/data.json")

