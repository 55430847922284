module.exports = [{
      plugin: require('/Users/doug/apps/Mainframe2/websites/node_modules/gatsby-plugin-react-native-web/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/doug/apps/Mainframe2/websites/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WV7ZGFW"},
    },{
      plugin: require('/Users/doug/apps/Mainframe2/websites/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-51965375-5","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/Users/doug/apps/Mainframe2/websites/packages/mainframe.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
